<template>
    <div>
        <PageIntroduce :title="'隐私声明管理'" :desc="'企业需依法收集和使用个人信息，在业务中对个人信息的采集、处理、分享等各项行为进行明确声明，包括Web网站，移动客户端，微信小程序等。'" :tips="'中国个人信息保护法 第六条 第十条 第十三条'"/>
        <CooperationTable :btnPermsObj="btnPermsObj"/>
        <CooperationCard :btnPermsObj="btnPermsObj"/>
    </div>
</template>
<script>
import PageIntroduce from '@core/components/pageIntroduce/PageIntroduce.vue'
import CooperationTable from './CooperationTable'
import CooperationCard from './CooperationCard'
import { error, findBtnPerm, success,getBtnPerms, verifyBtnPerm } from '@core/utils/utils'
  export default {
    components: { CooperationTable,CooperationCard,PageIntroduce },
    data() {
      return {
         btnPermsObj: {
           savePerm: findBtnPerm('cooperation-2'),
           copyPerm: findBtnPerm('cooperation-3'),
           updatePerm: findBtnPerm('cooperation-4'),
           readPerm: findBtnPerm('cooperation-5'),
           delPerm: findBtnPerm('cooperation-6'),
        },
      }
    },
    created() {
      getBtnPerms((btnPerms) => {
        this.btnPermsObj.savePerm = verifyBtnPerm(btnPerms, 'cooperation-2')
        this.btnPermsObj.copyPerm = verifyBtnPerm(btnPerms, 'cooperation-3')
        this.btnPermsObj.updatePerm = verifyBtnPerm(btnPerms, 'cooperation-4')
        this.btnPermsObj.readPerm = verifyBtnPerm(btnPerms, 'cooperation-5')
        this.btnPermsObj.delPerm = verifyBtnPerm(btnPerms, 'cooperation-6')
      })
    }
  }
</script>
<style lang="scss">

</style>
