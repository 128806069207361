<template>
  <b-card>
    <div class="filter-wrap flex-between">
      <div class="flex-start search-filter-wrap">
        <el-input
          v-model="listQuery.SearchStr"
          width="180"
          placeholder="请输入内容"
          clearable
          @clear="handleFilter"
        />
        <el-button
          size="small"
          type="primary"
          icon="el-icon-search"
          @click="handleFilter"
        >
          搜索
        </el-button>
      </div>
    </div>
    <div class="table-wrap">
      <FilterSelectList
        :list-query.sync="listQuery"
        :select-list.sync="selectList"
        @handleFilter="handleFilter"
      />
      <el-table
        v-loading="loading"
        fit
        border
        highlight-current-row
        :data="tableData"
        stripe
        style="width: 100%"
        @sort-change="sortChange"
      >
        <el-table-column
          prop="name"
          :show-overflow-tooltip="true"
          align="left"
          label="协议名称"
        />
        <el-table-column
          prop="thirdPartyName"
          :show-overflow-tooltip="true"
          sortable
          label="第三方"
        >
          <template
            slot="header"
            slot-scope="scope"
          >
            <TableHeaderFilterDropdown
              :key-str="'thirdPartyId'"
              :select-list.sync="selectList"
              :label="'第三方'"
              :value.sync="listQuery.thirdPartyId"
              :dropdown-options="thirdPartyOptions"
              @handleFilter="handleFilter"
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="agreementTypeName"
          :show-overflow-tooltip="true"
          sortable
          label="来源"
        >
          <template
            slot="header"
            slot-scope="scope"
          >
            <TableHeaderFilterDropdown
              :key-str="'templateCode'"
              :select-list.sync="selectList"
              :label="'来源'"
              :value.sync="listQuery.templateCode"
              :dropdown-options="templateOptions"
              @handleFilter="handleFilter"
            />
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="createUserName"
          width="120"
          label="创建者"
        />
        <el-table-column
          prop="createTime"
          width="100"
          sortable
          label="创建时间"
        >
          <template slot-scope="{row}">
            {{ formatDateStr(row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="140px"
        >
          <template slot-scope="{row}">
            <el-link
              v-if="btnPermsObj.copyPerm"
              :underline="false"
              class="mr10"
              type="primary"
              @click="copyPolicy(row)"
            >
              <el-tooltip
                content="复制"
                placement="top"
                effect="light"
              >
                <i class="el-icon-document-copy" />
              </el-tooltip>
            </el-link>
            <el-link
              v-if="btnPermsObj.updatePerm"
              class="mr10"
              :underline="false"
              type="primary"
              @click="toSave(row.id)"
            >
              <el-tooltip
                content="修改"
                placement="top"
                effect="light"
              >
                <i class="el-icon-edit" />
              </el-tooltip>
            </el-link>
            <el-link
              v-if="btnPermsObj.readPerm"
              class="mr10"
              :underline="false"
              type="primary"
              @click="preview(row.id)"
            >
              <el-tooltip
                content="预览"
                placement="top"
                effect="light"
              >
                <i class="el-icon-document" />
              </el-tooltip>
            </el-link>
            <el-link
              v-if="btnPermsObj.delPerm"
              :underline="false"
              type="primary"
              @click="del(row.id)"
            >
              <el-tooltip
                content="删除"
                placement="top"
                effect="light"
              >
                <i class="el-icon-delete" />
              </el-tooltip>
            </el-link>
          </template>
        </el-table-column>
      </el-table>
      <PageQuery
        v-show="total>0"
        :total="total"
        :cache-name="'listQuery16'"
        :update-state="'UPDATE_LISTQUERY16'"
        :list-query.sync="listQuery"
        :init-list-query.sync="initListQuery"
        @pagination="getList"
      />
    </div>
  </b-card>
</template>

<script>
import pagination from '@core/components/pagination/Pagination'
import { StatementPage, statementDel } from '@/api/enterprise/statement'
import { error, findBtnPerm, success } from '@core/utils/utils'
import FilterSelectList from '@core/components/filter-select-list/FilterSelectList'
import TableHeaderFilterDropdown from '@core/components/table-header-fliter-dropdown/TableHeaderFilterDropdown'
import {
  GetAgreementListByPage,
  DeleteAgreementByIds,
  GetTemplateList,
} from '@/api/thirdPartCompliance/thirdPartCompliance'
import { GetThirdPartyOptionList } from '@/api/enterprise/assest'

export default {
  components: {
    pagination,
    FilterSelectList,
    TableHeaderFilterDropdown,
  },
  props: {
    btnPermsObj: {
      type: Object,
    },
  },
  data() {
    const listQuery = {
      page: 1,
      pageSize: 5,
      SearchStr: '',
      templateCode: '',
      thirdPartyId: 0,
      orderBy: {
        createTime: '',
        source: '',
        thirdParty: '',
      },
    }
    return {
      thirdPartyOptions: [],
      templateOptions: [],
      selectList: [],
      totalShow: true,
      loading: false,
      total: 0,
      listQuery: { ...listQuery },
      initListQuery: { ...listQuery },
      tableData: [],
    }
  },
  created() {
    this.getTemplateList()
    const cacheQuery = this.$store.state.pageQuery.listQuery16
    this.listQuery = { ...cacheQuery }
    this.getList()
    this.getThirdPartyOptions()
  },
  methods: {
    sortChange(column) {
      const order = column.order ? column.order : 'ascending'
      const orderObj = {
        descending: 'desc',
        ascending: 'asc',
      }
      this.listQuery.orderBy[column.prop] = orderObj[order]
      this.getList()
    },
    getTemplateList() {
      GetTemplateList()
        .then(res => {
          const resData = res.data
          if (resData.code === 0) {
            resData.data.forEach(r => {
              this.templateOptions.push({
                label: r.templateName,
                value: r.code,
              })
            })
          }
        })
    },
    getThirdPartyOptions() {
      GetThirdPartyOptionList()
        .then(res => {
          const resData = res.data
          if (resData.code === 0) {
            resData.data.forEach(r => {
              this.thirdPartyOptions.push({
                label: r.name,
                value: r.id,
              })
            })
          }
        })
    },
    copyPolicy(row) {
      this.$router.push({
        path: '/cooperation-save',
        query: {
          id: row.id,
          c: 1,
        },
      })
    },
    preview(id) {
      const routeUrl = this.$router.resolve({
        path: '/cooperation-read',
        query: { id },
      })
      window.open(routeUrl.href, '_blank')
    },
    toSave(id) {
      this.$router.push({
        path: '/cooperation-save',
        query: { id },
      })
    },
    getList() {
      this.loading = true
      this.listQuery.thirdPartyId = this.listQuery.thirdPartyId ? this.listQuery.thirdPartyId : 0
      GetAgreementListByPage(this.listQuery)
        .then(res => {
          this.loading = false
          this.tableData = res.data.data.list
          this.total = Number(res.data.data.total)
        })
        .catch(() => {
          this.loading = false
        })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    del(id) {
      this.$confirm('是否确认吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      })
        .then(() => {
          const ids = []
          ids.push(id)
          DeleteAgreementByIds({ ids })
            .then(res => {
              const resData = res.data
              if (resData.code === 0) {
                success(resData.msg)
                this.getList()
              } else {
                error(resData.msg)
              }
            })
        })
    },
  },
}
</script>

<style scoped>

</style>
