<template>
  <b-card
    v-loading="loading"
    title="声明模板"
  >
    <b-row
      v-show="list.length>0"
      class="match-height"
    >
      <b-col
        v-for="(item,index) in list"
        :key="index"
        xl="3"
        lg="3"
        md="3"
        @click="toSave(item.code)"
      >
        <div class="card-list mb-1">
          <div class="card-list-header">
            <h4 class="card-list-title overflow-ellipsis">
              {{ item.templateName }}
            </h4>
            <div class="card-line mb-0" />
          </div>
          <div class="card-list-body">
            <span
              class="overflow-ellipsis card-list-abstract"
              style="line-height: 1.5rem;"
            >{{ item.description }}</span>
          </div>
          <div class="text-right card-list-operate">
            <el-link
              v-show="btnPermsObj.savePerm"
              class="mr10"
              :underline="false"
              type="primary"
              @click="toSave(item.code)"
            >
              <span class="font-smaller-3 flex-between">
                <i class="el-icon-s-promotion" />
                <span class="f12">新建</span>
              </span>
            </el-link>
          </div>
        </div>
      </b-col>
    </b-row>
    <el-empty
      v-show="list.length == 0"
      description="暂无数据"
    />
  </b-card>
</template>

<script>
import { findBtnPerm, error } from '@core/utils/utils'
import { GetTemplateList } from '@/api/thirdPartCompliance/thirdPartCompliance'

export default {
  props: {
    btnPermsObj: {
      type: Object,
    },
  },
  data() {
    return {
      list: [],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    toSave(id) {
      if (this.btnPermsObj.savePerm) {
        this.$router.push({
          path: 'cooperation-save',
          query: { tId: id },
        })
      } else {
        error('您无创建权限，请联系管理员')
      }
    },
    getList() {
      this.loading = true
      GetTemplateList()
        .then(res => {
          this.loading = false
          const resData = res.data
          if (resData.code === 0) {
            this.list = resData.data
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style scoped>

</style>
